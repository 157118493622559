import Cookies from 'js-cookie';
import Shepherd from 'shepherd.js';

var Abraham = new Object();

Abraham.tours = {};
Abraham.incompleteTours = [];
Abraham.startTour = function(tourName) {
  if (!Shepherd.activeTour) {
  	Abraham.tours[tourName].start();
  }
};
Abraham.startNextIncompleteTour = function() {
  if (Abraham.incompleteTours.length) {
  	Abraham.tours[Abraham.incompleteTours[0]].checkAndStart();
  }
};

document.addEventListener("DOMContentLoaded", Abraham.startNextIncompleteTour);

global.Abraham = Abraham;
global.Shepherd = Shepherd;
global.Cookies = Cookies;
